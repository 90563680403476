jQuery(document).ready(function($) {

    //Submenu hover
    $('.menu-item-has-children').hover(function() {
        $('header').toggleClass('header__hover');
    });
    $('.sub__menu--banner').hover(function() {
        $('header').toggleClass('header__hover');
    });

    //Owl
     $("#banner__owl").owlCarousel({
        items: 1,
        nav: true,
        navText: ["<span></span>","<span></span>"],
      });

     $("#products__owl").owlCarousel({
        items: 1,
        nav: true,
        navText: ["<span></span>","<span></span>"],
        margin: 30,
        responsive:{
            500:{
                items:2,
                margin: 20,
            },
            990:{
                items:4,
                margin: 30,
            },
            1100:{
                items:4,
                margin: 30,
            }
        }
      });

     $("#my-slider").sliderPro({
        width: 390,
        height: 500,
        orientation: "vertical",
        loop: !1,
        arrows: !0,
        autoplay: !1,
        buttons: !1,
        thumbnailsPosition: "bottom",
        thumbnailPointer: !0,
        thumbnailWidth: 115,
        thumbnailHeight: 100,
        breakpoints: {
            1600: {
                thumbnailWidth: 115,
                thumbnailHeight: 100,
            },
            1500: {
                thumbnailWidth: 115,
                thumbnailHeight: 100,
            },
            1400: {
                thumbnailWidth: 115,
                thumbnailHeight: 100,
            },
            1200: {
                thumbnailWidth: 134,
                thumbnailHeight: 90,
            },
            990: {
                width: 650,
                thumbnailWidth: 115,
                thumbnailHeight: 100,
            },
            500: {
                width: 290,
                height: 230,
                thumbnailWidth: 134,
                thumbnailHeight: 100,
            },
            400: {
                width: 290,
                height: 230,
                thumbnailWidth: 134,
                thumbnailHeight: 100,
            },
            350: {
                width: 290,
                height: 230,
                thumbnailWidth: 134,
                thumbnailHeight: 100,
            },
        }
    });

     //Second block scroll
     $(".to__second").click(function(e) {
        e.preventDefault();
        $('html, body').animate({
            scrollTop: $("#second__block").offset().top
        }, 1000);
    });

     //Scroll to top
     $('.top__top').on('click', function() {
        $('body, html').animate({scrollTop: 0}, 1500);
     });

     

     //Product category tabs
     $(".spec__tab--content").not(":first").hide();
     $(".sprec__tabs__items .tab_item").click(function() {
     $(".sprec__tabs__items .tab_item").removeClass("current").eq($(this).index()).addClass("current");
     var text2 = $(this).text()
     $(".spec__tab--content").hide().eq($(this).index()).fadeIn();
     $('.current__tab').html(text2);
     }).eq(0).addClass("current");

     

     //Single product accordeon
     $('.accordeon__wrap').on('click', function() {
        $(this).toggleClass('active');
        $('.accordeon__top', this).toggleClass('active');
        $('.accordeon__content', this).toggleClass('active');
     });
     $('.mobile__menu').css('z-index', '999');

     //Mobile menu
     $('.menu__button').on('click', function() {
        $(this).toggleClass('close__menu');
        $(this).parent().toggleClass('close__menu--wrap');
        $('.mobile__menu').toggle('medium');
     });

     //Resources tabs
     $(".resources__tab--item").not(":first").hide();
     $(".res__tab").click(function() {
     $(".res__tab").removeClass("current").eq($(this).index()).addClass("current");
     $(".resources__tab--item").hide().eq($(this).index()).fadeIn()
     }).eq(0).addClass("current");

     //Popups
     $('.register__button').on('click', function(e) {
        e.preventDefault();
        $('.login__popup').hide();
        $('.register__popup').css('display', 'flex');
     });
     $('.enter__button').on('click', function(e) {
        e.preventDefault();
        $('.register__popup').hide();
        $('.login__popup').css('display', 'flex');
     });
     $('.close__popup').on('click', function(e) {
        e.preventDefault();
        $('.login__popup--wrap').fadeOut();
     });

     //Top bar scroll
        var iScrollPos = 0;
        $(document).scroll(function () {
            var iCurScrollPos = $(this).scrollTop();
            if (iCurScrollPos > iScrollPos) {
                $('.language').addClass('topbar__hidden');
                $('header').addClass('header__top');
            } else if(iCurScrollPos <= iScrollPos) {
               $('.language').removeClass('topbar__hidden');
               $('header').removeClass('header__top');
            }
            //iScrollPos = iCurScrollPos;
            console.log(iScrollPos);
            console.log(iCurScrollPos);
        });


     if(innerWidth < 800) {
        $('.map__point--wrap').on('click', function() {
            $('.map__point', this).css('z-index', 99);
            $('.point__logo', this).css('z-index', 90);
        });
        $('.map__point--wrap').on('click', function(e) {
            e.stopPropagation();
        });
        $('body').on('click', function() {
            $('.map__point').css('z-index', 1);
            $('.point__logo').css('z-index', 0);;
        });
     }

     if(innerWidth < 600) {
        $('.current__tab').addClass('hello');
        var text = $('.sprec__tabs__items .current').text();
        console.log(text);
        $('.current__tab').html(text);
        $('.current__tab').on('click', function() {
            $('.sprec__tabs__items').toggle();
        });
        $('.sprec__tabs__items .tab_item').on('click', function() {
            $('.sprec__tabs__items').fadeOut();
        });
     }

     //Block show animation
     AOS.init({
        duration: 1000,
        easing: 'ease-out-sine',
        once: true,
        disable: function () {
            var maxWidth = 768;
            return window.innerWidth <= maxWidth;
        }
     });


});